import AxiosConfig from '../../utils/axios-config.ts';

// * import types * //
import type { GetPortsRequest } from './requests/port.ts';
import type { GetPortsResponse, PortResponse } from './responses/port.ts';

export default class Port extends AxiosConfig {
  private readonly portsEndpoint = '/ports';

  /**
   * Retrieves a paginated list of ports
   * @param params - Optional query parameters for filtering and pagination
   * @returns Promise resolving to paginated port data
   * @throws ApiError if the request fails
   */

  async getAllPortsAPI(params?: GetPortsRequest): Promise<PortResponse[]> {
    const allData: PortResponse[] = [];
    let currentPage = 1;
    let totalPages = 1;
    const perPage = 300;

    const queryParams = { ...params, page: currentPage, perPage: perPage };

    do {
      const response = await this.useAxios.get<GetPortsResponse>(this.portsEndpoint, { params: queryParams });
      const { data } = response; // Assuming `data` contains ports and `meta` contains pagination info.

      allData.push(...data.data);

      // Update currentPage and totalPages based on meta information.
      currentPage = data.page;
      totalPages = data.totalPage;

      // Prepare for the next page request
      queryParams.page = currentPage + 1;
    } while (currentPage < totalPages);

    return allData;
  }
}
