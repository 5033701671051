import AxiosConfig from '../../utils/axios-config.ts';
import Country from './country.ts';
import CarrierTypes from './carrier-types.ts';
import ReverseOrder from './reverse-order.ts';
import Statuses from './statuses.ts';
import OmsConfig from './oms-config.ts';
import Port from './port.ts';
import Auth from './auth.ts';

// * import types * //
import type { GetSsoClientResponse } from './responses/sso.js';

export default class PublicService extends AxiosConfig {
  country: Country;
  carrierTypes: CarrierTypes;
  reverseOrder: ReverseOrder;
  statuses: Statuses;
  omsConfig: OmsConfig;
  port: Port;
  auth: Auth;

  private readonly ssoClientsEndpoint = '/sso-clients';

  constructor() {
    const baseURL = '/api/data';

    super(baseURL);

    this.country = new Country(baseURL);
    this.carrierTypes = new CarrierTypes(baseURL);
    this.reverseOrder = new ReverseOrder('/api');
    this.statuses = new Statuses(baseURL);
    this.omsConfig = new OmsConfig(baseURL);
    this.port = new Port(baseURL);
    this.auth = new Auth('/api');
  }

  async getSsoClientsAPI(): Promise<GetSsoClientResponse[]> {
    const response = await this.useAxios.get<GetSsoClientResponse[]>(this.ssoClientsEndpoint);
    return response.data;
  }
}
