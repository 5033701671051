import AxiosConfig from '../../utils/axios-config.ts';
import { OtpLoginRequest } from './requests/auth.ts';
import { OtpLoginResponse } from './responses/auth.ts';

export default class Auth extends AxiosConfig {
  private readonly otpLoginEndpoint = '/login/otp';

  async postVerifyOTPAPI(request: OtpLoginRequest): Promise<OtpLoginResponse> {
    const response = await this.useAxios.post(this.otpLoginEndpoint, request);
    return response.data;
  }
}
