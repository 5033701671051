import AxiosConfig from '../../utils/axios-config.ts';
import { GetMetabaseCustomerAnalytic, GetMetabaseCustomerB2BAnalytic } from './responses/analytic.ts';

export default class Analytic extends AxiosConfig {
  async getMetabaseCustomerAnalytics(): Promise<GetMetabaseCustomerAnalytic> {
    const response = await this.useAxios.get('/analytics/metabase');
    return response.data as GetMetabaseCustomerAnalytic;
  }

  async getMetabaseCustomerB2BAnalytics(): Promise<GetMetabaseCustomerB2BAnalytic> {
    const response = await this.useAxios.get('/analytics/b2b/metabase');
    return response.data as GetMetabaseCustomerB2BAnalytic;
  }
}
