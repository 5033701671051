import AxiosConfig from '../../utils/axios-config.ts';
import { GetAddOnRequest } from './requests/add-ons.ts';
import { GetAddOnResponse } from './responses/add-on.ts';

export default class AddOns extends AxiosConfig {
  private readonly endpoint = '/addons';

  async getAddOn(request: GetAddOnRequest): Promise<GetAddOnResponse> {
    const response = await this.useAxios.get<GetAddOnResponse>(`${this.endpoint}/${request.addOn}`);
    return response.data;
  }
}
