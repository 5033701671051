import AxiosConfig from '../../utils/axios-config.ts';
import { OmsConfigPresignedURLsPayload, UpsertOmsConfigPayload } from './requests/oms-config.ts';
import { GetOmsConfigResponse } from './responses/oms-config.ts';

export default class OmsConfig extends AxiosConfig {
  private readonly baseEndpoint = '/oms-config';
  private readonly presignedUrlBaseEndpoint = '/oms-configs';

  async upsertOmsConfig(payload: UpsertOmsConfigPayload) {
    return await this.useAxios.post(this.baseEndpoint, payload);
  }

  async postPresignedUrl(payload: OmsConfigPresignedURLsPayload[], clientSlug: string) {
    return (await this.useAxios.post(`${this.presignedUrlBaseEndpoint}/${clientSlug}/presigned-urls`, payload)).data;
  }

  async getOmsConfig() {
    return (await this.useAxios.get(this.baseEndpoint)).data as GetOmsConfigResponse;
  }
}
