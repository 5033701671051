import AxiosConfig from '../../utils/axios-config.ts';
import OrderB2B from './order-b2b.ts';
import Profile from './profile.ts';
import AddressBook from './address-book.ts';
import Auth from './auth.ts';
import OrderB2C from './order-b2c.ts';
import Users from './user.ts';
import AddOns from './add-ons.ts';
import Analytic from './analytic.ts';

export default class CustomerService extends AxiosConfig {
  profile: Profile;
  orderB2B: OrderB2B;
  orderB2C: OrderB2C;
  addressBook: AddressBook;
  auth: Auth;
  addOn: AddOns;
  user: Users;
  analytic: Analytic;

  constructor(token?: string) {
    const baseURL = '/api/customers';

    super(baseURL, token);

    this.profile = new Profile(baseURL, token);
    this.orderB2B = new OrderB2B(baseURL, token);
    this.orderB2C = new OrderB2C(baseURL, token);
    this.addressBook = new AddressBook(baseURL, token);
    this.auth = new Auth(baseURL);
    this.addOn = new AddOns(baseURL, token);
    this.user = new Users(baseURL, token);
    this.analytic = new Analytic(baseURL, token);
  }
}
