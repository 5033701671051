import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';

// import types
import type { Customer, GetEventSubscriptionsResponse, OmsProfileResponse } from './responses/profile.ts';
import type { GetEventSubscriptionsRequest, PostEventSubscriptionRequest } from './requests/profile.ts';

export default class Profile extends AxiosConfig {
  private readonly profileEndpoint = '/profile';
  private readonly subscriptionEndpoint = '/event-subscriptions';
  private readonly profileOmsEndpoint = '/profile/oms';

  async getProfile() {
    const response = await this.useAxios.get<Customer>(this.profileEndpoint);

    return response?.data;
  }

  async getEventSubscriptionsAPI(request: GetEventSubscriptionsRequest): Promise<GetEventSubscriptionsResponse> {
    const response = await this.useAxios.get(
      `${this.subscriptionEndpoint}` + qs<GetEventSubscriptionsRequest>(setDefaultIfRequired(request)),
    );

    return response.data as GetEventSubscriptionsResponse;
  }

  async postSubscriptionAPI(payload: PostEventSubscriptionRequest) {
    return this.useAxios.post(this.subscriptionEndpoint, payload);
  }

  async deleteSubscriptionAPI(id: string) {
    await this.useAxios.delete(this.subscriptionEndpoint + '/' + id);
  }

  async getOmsProfile(): Promise<OmsProfileResponse> {
    const response = await this.useAxios.get<OmsProfileResponse>(this.profileOmsEndpoint);

    return response?.data;
  }
}
