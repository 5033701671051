import AxiosConfig from '../../utils/axios-config.ts';
import { GetOMSConfigRequest } from './requests/oms-config.ts';
import { OMSConfigResponse } from './responses/oms-config.ts';

// import types

export default class OmsConfig extends AxiosConfig {
  private readonly omsConfigEndpoint = '/oms-config';

  async getOMSConfigAPI(params?: GetOMSConfigRequest): Promise<OMSConfigResponse> {
    if (params?.host?.trim() || params?.slug?.trim()) {
      const responses = await this.useAxios.get<OMSConfigResponse>(this.omsConfigEndpoint, {
        params,
      });

      return responses.data;
    }
    return {} as OMSConfigResponse;
  }
}
