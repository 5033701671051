import axios from 'axios';
import type { TypedResponse } from '@remix-run/node';
import { json } from '@remix-run/node';
import type { GenericActionResponse } from '../types/actions';
import * as Sentry from '@sentry/remix';

export function toErrorJson(error: unknown, data?: unknown): TypedResponse<GenericActionResponse> {
  const formattedResponse = { success: false, error: {} };
  console.error(error);

  if (axios.isAxiosError(error)) {
    const { response, request } = error;

    if (response) {
      const responseData = response.data;
      const responseType = typeof responseData;

      // Check if response data is a Buffer
      if (responseType === 'object' && responseData instanceof Buffer) {
        formattedResponse.error = {
          data: JSON.parse(responseData.toString('utf-8')), // Convert Buffer to string for user-friendliness
          status: response.status,
        };
      } else {
        // Handle other response data types (JSON, etc.)
        formattedResponse.error = { data: responseData, status: response.status };
      }
    } else if (request) {
      formattedResponse.error = { data: error.request };
    } else {
      formattedResponse.error = { message: error.message };
    }
  } else {
    // Handle non-Axios errors
    formattedResponse.error = { message: (error as Error).message };
  }

  return json(data ? { ...formattedResponse, ...data } : formattedResponse);
}

export function captureException(error: unknown) {
  Sentry.captureException(error);
}
