import { useState, useEffect } from 'react';
/* eslint-disable react/prop-types */
interface LogoProps {
  loginLogo: string;
}

const Logo: React.FC<LogoProps> = ({ loginLogo }) => {
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setError(false);

    const img = new Image();
    img.src = loginLogo;

    img.onload = () => {
      if (img.width > img.height) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
      setIsLoading(false);
    };

    img.onerror = () => {
      setError(true);
      setIsLoading(false);
    };
  }, [loginLogo]);

  if (error) {
    return <div className="text-red-500">Failed to load logo</div>;
  }

  if (isLoading) {
    return <div className="size-24 animate-pulse bg-gray-200" />;
  }

  // Determine the width based on landscape and image width
  const logoWidth = isLandscape ? 'w-72' : 'w-20';

  return <img src={loginLogo} alt={`logo`} className={`h-auto ${logoWidth} object-cover`} loading="eager" />;
};

export default Logo;
