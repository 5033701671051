import AxiosConfig from '../../utils/axios-config.ts';
import { qs, setDefaultIfRequired } from '../../utils/helper.ts';
import { GetUsersResponse } from './responses/user.ts';
import { GetUsersRequest } from './requests/user.ts';
import { CreateUserRequest, UpdateUserRequest, UpdateUserStatusRequest } from './requests/user.ts';

export default class Users extends AxiosConfig {
  private readonly endpoint = '/users';

  async getUsers(request: GetUsersRequest): Promise<GetUsersResponse> {
    const response = await this.useAxios.get(this.endpoint + qs<GetUsersRequest>(setDefaultIfRequired(request)));
    return response.data as GetUsersResponse;
  }

  async createUser(payload: CreateUserRequest): Promise<void> {
    return this.useAxios.post(this.endpoint, payload);
  }

  async updateUser(userID: string, payload: UpdateUserRequest): Promise<void> {
    return this.useAxios.patch(`${this.endpoint}/${userID}`, payload);
  }

  async updateUserStatus(payload: UpdateUserStatusRequest): Promise<void> {
    return this.useAxios.post(`${this.endpoint}/enabler`, payload);
  }
}
