import AxiosConfig from '../../utils/axios-config.ts';
import Customer from './customer.ts';
import LControl from './l-control.ts';
import OrderB2B from './order-b2b.ts';
import Partner from './partner.ts';
import Profile from './profile.ts';
import Sendbird from './sendbird.ts';
import AddressBook from './address-book.ts';
import Zone from './zone.ts';
import ServiceLevel from './service-level.js';
import OrderB2C from './order-b2c.ts';
import OmsConfig from './oms-config.js';

export default class ClientService extends AxiosConfig {
  lControl: LControl;
  partner: Partner;
  sendbird: Sendbird;
  profile: Profile;
  customer: Customer;
  orderB2B: OrderB2B;
  orderB2C: OrderB2C;
  serviceLevel: ServiceLevel;
  addressBook: AddressBook;
  zone: Zone;
  omsConfig: OmsConfig;

  constructor(token?: string) {
    const baseURL = '/api/clients';

    super(baseURL, token);

    this.lControl = new LControl(baseURL, token);
    this.partner = new Partner(baseURL, token);
    this.sendbird = new Sendbird(baseURL, token);
    this.profile = new Profile(baseURL, token);
    this.customer = new Customer(baseURL, token);
    this.orderB2B = new OrderB2B(baseURL, token);
    this.orderB2C = new OrderB2C(baseURL, token);
    this.addressBook = new AddressBook(baseURL, token);
    this.zone = new Zone(baseURL, token);
    this.serviceLevel = new ServiceLevel(baseURL, token);
    this.omsConfig = new OmsConfig(baseURL, token);
  }
}
