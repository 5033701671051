import AxiosConfig from '../../utils/axios-config.ts';
import { qs } from '../../utils/helper.ts';
import {
  B2COrderRequest,
  DomesticOrderBatchRequest,
  GetB2COrdersQuery,
  OrdersExportPayload,
  OrdersExportQuery,
  ServiceLevelMappingRequest,
} from './requests/order/b2c.ts';
import {
  GetB2COrderDetailsResponse,
  GetB2COrderRateCalculationResponse,
  GetB2COrdersResponse,
  GetServiceLevelMappingResponse,
} from './responses/order/b2c.ts';

export default class OrderB2C extends AxiosConfig {
  private readonly ordersEndpoint = '/orders';
  private readonly serviceLevelMappingEndpoint = '/service-level-mappings';
  private readonly exportEndpoint = '/v3/orders/export';

  async getServiceLevelMappingAPI(request: ServiceLevelMappingRequest): Promise<GetServiceLevelMappingResponse> {
    const response = await this.useAxios.get(this.serviceLevelMappingEndpoint + qs<ServiceLevelMappingRequest>(request));

    return response.data as GetServiceLevelMappingResponse;
  }

  async postB2COrdersAPI(type: 'domesticSingleForm' | 'documentSingleForm' | 'crossBorderSingleForm', payload: B2COrderRequest[]) {
    return await this.useAxios.post(`${this.ordersEndpoint}/${type}`, payload);
  }

  async getOrders(params: GetB2COrdersQuery) {
    const queryParams = qs<GetB2COrdersQuery>(params);
    const response = await this.useAxios.get<GetB2COrdersResponse>(this.ordersEndpoint + queryParams);

    return response.data;
  }

  async exportOrders(payload: OrdersExportPayload, params: OrdersExportQuery) {
    const response = await this.useAxios.post(`${this.exportEndpoint}`, payload, { params, responseType: 'arraybuffer' });
    return response.data;
  }

  async getOrder(id: string) {
    return (await this.useAxios.get<GetB2COrderDetailsResponse>(`${this.ordersEndpoint}/${id}`)).data;
  }

  async getOrderRateCalculation(id: string) {
    return (await this.useAxios.get<GetB2COrderRateCalculationResponse>(`${this.ordersEndpoint}/${id}/rate-calculation`)).data;
  }

  async postBatchB2COrdersAPI(type: 'domesticCsv' | 'crossBorderCsv' | 'documentCsv', payload: DomesticOrderBatchRequest[]) {
    return await this.useAxios.post(`${this.ordersEndpoint}/${type}`, payload);
  }
}
