import { Button, Form, FormControl, FormField, FormItem, FormLabel, Input } from '@repo/ui-shadcn';
import LoginLogo from '../assets/login.png';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { APIService } from '@repo/api-services';
import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction, TypedResponse } from '@remix-run/node';
import { GenericActionResponse } from '../types/actions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useActionData, useLoaderData, useSubmit } from '@remix-run/react';
import { redirect } from '@remix-run/node';
import { toErrorJson } from '../lib/error';
import { omsSettingsCookie, tokenCookie, userCookie, userOtpCookie } from '../storage/oms-cookie.server';
import { LoginRequest } from '@repo/api-services/domains/customer/requests/auth';
import { toast } from '@repo/ui-shadcn/components/ui/toast/use-toast';
import { loginFormSchema, LoginFormSchema } from '../schemas/login';
import { GetOMSConfigRequest } from '@repo/api-services/domains/public/requests/oms-config';
import { OMSConfigResponse } from '@repo/api-services/domains/public/responses/oms-config';
import { toResponseJson } from '../lib/response';
import Logo from '../components/layouts/logo';
import { EyeOff, Eye } from 'lucide-react';

export async function loader(args: LoaderFunctionArgs) {
  const publicService = new APIService.public();

  const host = args.request.headers.get('host');
  const params: GetOMSConfigRequest = {};

  if (host && host.trim() !== '' && !['localhost', 'luwjistik.io'].some((h) => host.includes(h))) {
    params.host = host;
  }

  let omsConfig: OMSConfigResponse | null = null;
  try {
    if (Object.keys(params).length > 0) {
      omsConfig = await publicService.omsConfig.getOMSConfigAPI(params);
    }
  } catch {
    omsConfig = null;
  }

  return toResponseJson({ omsConfig });
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data?.omsConfig?.properties?.portalLabel || data?.omsConfig?.host || '' },
    { tagName: 'link', rel: 'icon', href: data?.omsConfig?.properties?.favicon },
  ];
};

export default function LoginPage() {
  const { omsConfig } = useLoaderData<typeof loader>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionData = useActionData<typeof action>();
  const submit = useSubmit();

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const { slug } = omsConfig || {};
  const { loginBackgroundColour, pageButtonColour, loginLogo, loginPicture } = omsConfig?.properties || {};

  const loginImage = useMemo(() => (slug ? loginPicture : LoginLogo), [slug, loginPicture]);

  const form = useForm<LoginFormSchema>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      clientSlug: slug ? slug : '',
      email: '',
      password: '',
    },
  });

  const onSubmit = useCallback(
    (values: LoginFormSchema) => {
      setIsSubmitting(true);

      // define the payload
      const payload: LoginRequest = {
        clientSlug: values.clientSlug,
        email: values.email,
        password: values.password,
      };
      const formData = new FormData();
      formData.append('loginPayload', JSON.stringify(payload));

      try {
        submit(formData, { method: 'post', encType: 'multipart/form-data' });
      } finally {
        setIsSubmitting(false);
      }
    },
    [submit],
  );

  useEffect(() => {
    const errorMessage = actionData?.error?.data?.error;
    if (actionData && !actionData.success && errorMessage) {
      toast({
        title: 'Failed to login',
        description: 'Please try again!',
        variant: 'destructive',
      });
    }
  }, [actionData]);

  return (
    <div className="grid min-h-screen grid-cols-1 bg-gray-100 bg-gradient-to-r lg:grid-cols-2">
      {/* Left Column - Login Form */}
      <div className="flex items-center justify-center bg-white p-8 lg:p-12">
        <div className="mx-auto w-full max-w-md space-y-8 rounded-lg p-8 shadow-lg ">
          <div className="space-y-2">
            {loginLogo && (
              <div className="my-8 flex items-center justify-start">
                <Logo loginLogo={loginLogo} />
              </div>
            )}
            <h2 className="text-2xl font-semibold tracking-tight">Welcome back!</h2>
            <p className="text-muted-foreground">Login to your customer account to continue</p>
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={`space-y-2 ${loginPicture ? '' : 'mx-auto lg:max-w-sm'}`}>
              {!omsConfig?.slug && (
                <FormField
                  control={form.control}
                  name="clientSlug"
                  render={({ field }) => (
                    <FormItem>
                      {/**
                       * For user convenience, we rename "client slug" to "portal code"
                       * in the UI while maintaining backward compatibility in the code
                       */}
                      <FormLabel>Portal Code</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter your Portal Code" {...field} required />
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter your email" type="email" {...field} required />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          placeholder="Enter your password"
                          type={isPasswordVisible ? 'text' : 'password'}
                          {...field}
                          required
                          className="pr-10"
                        />
                        <button
                          type="button"
                          onClick={() => setPasswordVisible((prev) => !prev)}
                          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500 hover:text-gray-700"
                        >
                          {isPasswordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
                        </button>
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />

              <div className="py-8">
                <Button
                  variant={'default'}
                  style={{ backgroundColor: pageButtonColour }}
                  className="w-full"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Login
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
      {/* Right side - Decorative */}
      <div
        className={`relative hidden w-full overflow-hidden lg:block ${!loginImage ? 'bg-cover' : ' bg-white'}`}
        style={!loginImage ? { backgroundColor: loginBackgroundColour } : {}}
      >
        {loginImage ? (
          <img src={loginImage} alt="Login background" className="insert-0 absolute size-full object-cover" />
        ) : (
          <div className="flex h-full flex-col items-center justify-center p-8 text-center">
            <h1 className="fade mb-4 text-4xl font-bold text-white">Hello, there!</h1>
            <p className="text-primary-foreground fade mb-8 max-w-md text-xl">
              We&apos;re excited to see you again. Login to access your account and continue your journey.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export const action = async ({ request }: ActionFunctionArgs): Promise<TypedResponse<GenericActionResponse>> => {
  const formData = await request.formData();

  const loginPayload = formData.get('loginPayload');
  if (!loginPayload) {
    return toErrorJson(new Error('Missing login payload'));
  }
  let payload: LoginRequest;
  try {
    payload = JSON.parse(loginPayload as string);
  } catch {
    return toErrorJson(new Error('Invalid login payload'));
  }

  const service = new APIService.customer();
  const publicService = new APIService.public();

  try {
    const response = await service.auth.postLogin(payload);

    if (response.otpEnabled) {
      const userOtpHeader = await userOtpCookie.serialize(response.userId);
      const headers = new Headers();
      headers.append('Set-Cookie', userOtpHeader);

      return redirect('/verify-otp', {
        headers: headers,
      });
    } else {
      const token = response.token;
      const tokenHeader = await tokenCookie.serialize(token);

      const customerService = new APIService.customer(token);
      const profileResponse = await customerService.profile.getProfile();
      const userHeader = await userCookie.serialize({
        name: response.customerName,
        email: response.email,
        slug: response.client?.slug,
        clientName: response.client?.name,
        isB2bActive: profileResponse.b2bShipmentMode?.length ?? 0 > 0,
      });

      const headers = new Headers();
      headers.append('Set-Cookie', tokenHeader);
      headers.append('Set-Cookie', userHeader);

      const params: GetOMSConfigRequest = {
        slug: response.client?.slug,
      };

      if (Object.keys(params).length > 0) {
        const omsConfigResponse = await publicService.omsConfig.getOMSConfigAPI(params);
        const omsConfigHeader = await omsSettingsCookie.serialize(omsConfigResponse);

        headers.append('Set-Cookie', omsConfigHeader);
      }

      return redirect('/', {
        headers: headers,
      });
    }
  } catch (error: unknown) {
    return toErrorJson(error);
  }
};
